import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/app/[locale]/(website)/components/application/TodayPatientsList.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/app/[locale]/(website)/components/CarouselClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/app/[locale]/(website)/components/icons/avg.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/app/[locale]/(website)/components/icons/gdpr.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/app/[locale]/(website)/components/icons/Iso.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/app/[locale]/(website)/components/icons/logo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/app/[locale]/(website)/components/icons/nen.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/app/[locale]/(website)/components/layout/HeroLines.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/app/[locale]/(website)/components/layout/HeroMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/opt/build/repo/node_modules/sonner/dist/index.mjs");
