'use client'
import {useTranslations} from 'use-intl'
export default function NenIcon() {
    const t = useTranslations()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            baseProfile="tiny"
            version="1.2"
            viewBox="0 0 200 200"
            width={200}
            fill="currentColor"
            aria-label={t('Arialabels.nen')}
            role="img"
        >
            <g>
                <g id="Layer_1">
                    <g>
                        <path
                            d="M81.1,115.3h37.7c.6,0,1.1.5,1.1,1.2v9.2c0,.6-.5,1.2-1.1,1.2h-37.7c-.6,0-1.1-.5-1.1-1.2v-9.2c0-.6.5-1.2,1.1-1.2Z"
                            fill="currentColor"
                        />
                        <path
                            d="M81.1,73.8h37.7c.6,0,1.1.5,1.1,1.2v9.2c0,.6-.5,1.2-1.1,1.2h-37.7c-.6,0-1.1-.5-1.1-1.2v-9.2c0-.6.5-1.2,1.1-1.2Z"
                            fill="currentColor"
                        />
                        <path
                            d="M162.4,94.4c0-6.6-4.4-9.8-9.2-9.8s-9.2,2.8-9.2,9.4v31.7c0,.6-.5,1.2-1.1,1.2h-10.5c-.6,0-1.1-.5-1.1-1.2v-50.7c0-.6.5-1.2,1.1-1.2h6.4c1,0,1.5.3,1.9.8.9.8,2.4,2.6,2.4,2.6,1.5-1.3,5.1-4.2,11.9-4.2,11.3,0,20.4,8.2,20.4,20v32.7c0,.6-.5,1.2-1.1,1.2h-10.5c-.6,0-1.1-.5-1.1-1.2v-31.3h0Z"
                            fill="currentColor"
                        />
                        <path
                            d="M81.1,136.1h10.5c.6,0,1.1.5,1.1,1.2v9.2c0,.6-.5,1.2-1.1,1.2h-10.5c-.6,0-1.1-.5-1.1-1.2v-9.2c0-.6.5-1.2,1.1-1.2Z"
                            fill="currentColor"
                        />
                        <path
                            d="M81.1,53.1h10.5c.6,0,1.1.5,1.1,1.2v9.2c0,.6-.5,1.2-1.1,1.2h-10.5c-.6,0-1.1-.5-1.1-1.2v-9.2c0-.6.5-1.2,1.1-1.2Z"
                            fill="currentColor"
                        />
                        <path
                            d="M81.1,94.6h21.7c.6,0,1.1.5,1.1,1.2v9.2c0,.6-.5,1.2-1.1,1.2h-21.7c-.6,0-1.1-.5-1.1-1.2v-9.2c0-.6.5-1.2,1.1-1.2Z"
                            fill="currentColor"
                        />
                        <path
                            d="M55.9,94.4c0-6.6-4.4-9.8-9.2-9.8s-9.2,2.8-9.2,9.4v31.7c0,.6-.5,1.2-1.1,1.2h-10.5c-.6,0-1.1-.5-1.1-1.2v-50.7c0-.6.5-1.2,1.1-1.2h6.4c1,0,1.5.3,1.9.8.9.8,2.4,2.6,2.4,2.6,1.5-1.3,5.1-4.2,11.9-4.2,11.3,0,20.4,8.2,20.4,20v32.7c0,.6-.5,1.2-1.1,1.2h-10.5c-.6,0-1.1-.5-1.1-1.2v-31.3h0Z"
                            fill="currentColor"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}
