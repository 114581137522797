import {cva} from 'class-variance-authority'

export const heroClasses = cva(
    'container mx-auto pl-[var(--page-padding-left)] pr-[var(--page-padding-right)]',
    {
        variants: {
            variant: {
                primary: 'flex flex-col text-center max-w-3xl',
                secondary: 'max-w-3xl pt-[--page-padding-top]',
                integrations: 'max-w-4xl pt-[--page-padding-top]',
            },
        },
    },
)

export const heroTagClasses = cva('text-center max-w-screen-md', {
    variants: {
        variant: {
            page: 'text-primary dark:text-dark-primary font-medium text-balance leading-none tracking-[-0.0125em]',
        },
    },
})

export const heroTitleClasses = cva('text-balance after:block after:mb-2 tracking-[-0.02em]', {
    variants: {
        variant: {
            primary: 'text-5xl lg:text-7xl text-primary font-semibold',
            secondary: 'text-5xl text-text-primary font-bold',
            tertiary: 'text-3xl text-text-primary font-semibold',
            quaternary: 'text-2xl text-text-secondary font-semibold',
            quinary: 'text-xl text-text-secondary font-medium',
            gradient: 'text-5xl lg:text-7xl font-bold text-gradient',
        },
        position: {
            center: 'text-center',
            left: 'text-left',
        },
    },
})

export const heroSubtitleClasses = cva('max-w-screen-md', {
    variants: {
        variant: {
            primary:
                'md:text-2xl text-xl text-text-secondary font-base text-balance mb-16 leading-snug',
            secondary: 'text-xl text-text-secondary font-medium text-balance',
            tertiary: 'text-base text-text-secondary',
        },
        position: {
            center: 'text-center',
            left: 'text-left',
        },
    },
})
