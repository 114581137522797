'use client'
import {CalendarIcon, ClockIcon} from '@heroicons/react/24/outline'
import {ArrowRightCircleIcon, CheckCircleIcon, PlayCircleIcon} from '@heroicons/react/24/solid'
import classNames from 'classnames'
import {useTranslations} from 'next-intl'
import {useCallback, useState} from 'react'
import {toast} from 'sonner'

import {buttonClasses} from '@/app/[locale]/(website)/components/ui/ButtonClasses'

import {Patient} from '../../types/types'
import getTodayPatients from '../data/TodayPatients'

export default function PatientList() {
    const {patients} = getTodayPatients()
    const initialPatientList: Patient[] = [...patients]
    const [patientList, setPatientList] = useState<Patient[]>(initialPatientList)
    const t = useTranslations()

    const updateStatus = useCallback(
        (patientId: string) => (_e: React.MouseEvent<HTMLElement>) => {
            const updatedPatients = patientList.map(function (patient) {
                if (patient.id === patientId && patient.status !== 'done') {
                    return {...patient, status: 'done'}
                }
                return patient
            })
            setPatientList(updatedPatients)
            toast.success('Patient status updated successfully', {
                duration: 1500,
                classNames: {
                    title: 'text-primary dark:text-dark-primary',
                    success: 'text-emerald-400/95',
                },
            })
        },
        [patientList],
    )

    const donePatients = patientList.filter((patient) => patient.status === 'done')
    const remainingPatients = patientList.filter((patient) => patient.status !== 'done')

    return (
        <div className="container mx-auto py-8">
            {donePatients.length > 0 && (
                <div className="text-xs mb-12">
                    <div className="flex flex-row border-b border-slate-200/50 gap-1 items-center py-2">
                        <CheckCircleIcon className="size-4 text-emerald-400/95" />
                        <span className="text-tertiary dark:text-dark-tertiary font-medium mr-1">
                            Done
                        </span>
                        <span className="text-tertiary dark:text-dark-tertiary">
                            {donePatients.length}
                        </span>
                    </div>
                    <ul>
                        {donePatients.map(function (patient) {
                            return (
                                <li
                                    key={patient.id}
                                    className="flex justify-between border-b border-slate-200/50 py-1"
                                >
                                    <div className="flex flex-row gap-1">
                                        <div className="flex flex-coljustify-between items-center h-5">
                                            <div className="w-[1rem]">
                                                {patient.icon && (
                                                    <patient.icon
                                                        className={`size-4 ${patient.colorClass}`}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-1">
                                            <div className="flex flex-row gap-1 h-5">
                                                <div className="flex justify-between items-center gap-2">
                                                    <span className="flex text-tertiary dark:text-dark-tertiary justify-between items-center">
                                                        <CalendarIcon className="size-4 inline mr-1" />
                                                        {patient.time}
                                                    </span>
                                                    <span className="flex text-tertiary dark:text-dark-tertiary justify-between items-center">
                                                        <ClockIcon className="size-4 inline mr-1" />
                                                        {patient.duration}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="flex flex-row gap-1 h-5">
                                                <div className="size-4 bg-gray-200 rounded-full" />
                                                <span className="text-tertiary dark:text-dark-tertiary font-bold">
                                                    {patient.name}
                                                </span>
                                                <span className="text-tertiary dark:text-dark-tertiary">
                                                    {patient.dob}
                                                </span>
                                                <span className="text-tertiary dark:text-dark-tertiary">
                                                    {patient.sex}
                                                </span>
                                                <span className="text-tertiary dark:text-dark-tertiary">
                                                    {patient.diagnosis}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="flex flex-row justify-between items-center gap-1">
                                            <span className="text-tertiary dark:text-dark-tertiary border border-slate-200/75 rounded-full h-5 px-1.5">
                                                {patient.appointmenttype}
                                            </span>
                                            <CheckCircleIcon className="size-4 text-emerald-400/95" />
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )}

            {remainingPatients.length > 0 && (
                <div className="text-xs mb-12">
                    <div className="flex flex-row border-b border-slate-200/50 gap-1 items-center py-2">
                        <div className="flex size-4 items-center justify-center">
                            <span className="size-[13px] border border-slate-600/50 rounded-full bg-slate-200/25" />
                        </div>
                        <span className="text-tertiary dark:text-dark-tertiary font-medium">
                            Todo
                        </span>
                        <span className="text-tertiary dark:text-dark-tertiary">
                            {remainingPatients.length}
                        </span>
                    </div>
                    <ul>
                        {remainingPatients.map(function (patient) {
                            return (
                                <li
                                    key={patient.id}
                                    className="flex flex-col border-b border-slate-200/50 gap-2 py-2"
                                >
                                    <div className="flex flex-row gap-1 text-tertiary dark:text-dark-tertiary justify-between">
                                        <div
                                            className={classNames(
                                                'flex flex-row gap-2',
                                                '[&_span]:flex [&_span]:items-center',
                                            )}
                                        >
                                            <span>
                                                <CalendarIcon className="size-4 inline mr-1" />{' '}
                                                {patient.time}
                                            </span>
                                            <span>
                                                <ClockIcon className="size-4 inline mr-1" />{' '}
                                                {patient.duration}
                                            </span>
                                            <span>{patient.appointmenttype}</span>
                                        </div>
                                        <div className="flex flex-col justify-between items-center patientlist">
                                            {patient.status !== 'done' && (
                                                <button
                                                    type="button"
                                                    onClick={updateStatus(patient.id)}
                                                    className={buttonClasses({
                                                        variant: 'patientList',
                                                        shape: 'round',
                                                    })}
                                                    aria-label={t('Arialabels.updateStatus')}
                                                >
                                                    {patient.status === 'planned' && (
                                                        <span className="size-[13px] border border-slate-600/50 rounded-full bg-slate-200/25" />
                                                    )}
                                                    {patient.status === 'arrived' && (
                                                        <ArrowRightCircleIcon className="size-4 text-blue-500/85" />
                                                    )}
                                                    {patient.status === 'in_progress' && (
                                                        <PlayCircleIcon className="size-4 text-sky-500/95" />
                                                    )}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex flex-row gap-2">
                                        <div className="size-8 bg-gray-200 rounded-full dark:bg-slate-500/45" />
                                        <div className="flex flex-col">
                                            <div className="flex flex-row gap-1">
                                                <span className="font-bold text-tertiary dark:text-dark-tertiary">
                                                    {patient.name}
                                                </span>
                                                <span className="text-tertiary dark:text-dark-tertiary">
                                                    {patient.sex}
                                                </span>
                                                <span className="text-tertiary dark:text-dark-tertiary">
                                                    {patient.dob}
                                                </span>
                                            </div>
                                            <div className="flex flex-col">
                                                <span className="text-tertiary dark:text-dark-tertiary">
                                                    {patient.diagnosis}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )}
        </div>
    )
}
