'use client'
import {useTranslations} from 'use-intl'
export default function AvgIcon() {
    const t = useTranslations()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            baseProfile="tiny"
            version="1.2"
            viewBox="0 0 200 200"
            width={200}
            fill="currentColor"
            aria-label={t('Arialabels.AVG')}
            role="img"
        >
            <g>
                <g id="Layer_1">
                    <path
                        d="M100,50.4l5.2,3.6-2-6.1,5.2-3.6h-6.4l-2-6.1-2,6.1h-6.4l5.2,3.6-2,6.1,5.2-3.6Z"
                        fill="currentColor"
                    />
                    <path
                        d="M100,157.1l5.2,3.8-2-6.1,5.2-3.6h-6.4l-2-6.1-2,6.1h-6.4l5.2,3.6-2,6.1,5.2-3.8Z"
                        fill="currentColor"
                    />
                    <path
                        d="M73.3,150l5.2,3.6-2-6.1,5.2-3.6h-6.4l-2-6.1-2,6.1h-6.4l5.2,3.6-2,6.1,5.2-3.6Z"
                        fill="currentColor"
                    />
                    <path
                        d="M53.9,130.4l5.2,3.8-2-6.1,5.2-3.6h-6.4l-2-6.1-2,6.1h-6.4l5.2,3.6-2,6.1,5.2-3.8Z"
                        fill="currentColor"
                    />
                    <path
                        d="M46.6,103.7l5.2,3.8-2-6.1,5.2-3.6h-6.4l-2-6.1-2,6.1h-6.2l5.2,3.6-2,6.1,5-3.8Z"
                        fill="currentColor"
                    />
                    <path
                        d="M53.9,77.1l5.2,3.6-2-6.1,5.2-3.6h-6.4l-2-6.1-2,6.1h-6.4l5.2,3.6-2,6.1,5.2-3.6Z"
                        fill="currentColor"
                    />
                    <path
                        d="M73.3,57.5l5.2,3.8-2-6.1,5.2-3.6h-6.4l-2-6.1-2,6.1h-6.4l5.2,3.6-2,6.1,5.2-3.8Z"
                        fill="currentColor"
                    />
                    <path
                        d="M126.7,150l5.2,3.6-2-6.1,5.2-3.6h-6.4l-2-6.1-2,6.1h-6.4l5.2,3.6-2,6.1,5.2-3.6Z"
                        fill="currentColor"
                    />
                    <path
                        d="M146.1,130.4l5.2,3.8-2-6.1,5.2-3.6h-6.4l-2-6.1-2,6.1h-6.4l5.2,3.6-2,6.1,5.2-3.8Z"
                        fill="currentColor"
                    />
                    <path
                        d="M153.3,103.7l5.2,3.8-2-6.1,5.2-3.6h-6.4l-2-6.1-2,6.1h-6.4l5.2,3.6-2,6.1,5.2-3.8Z"
                        fill="currentColor"
                    />
                    <path
                        d="M146.1,77.1l5.2,3.6-2-6.1,5.2-3.6h-6.4l-2-6.1-2,6.1h-6.4l5.2,3.6-2,6.1,5.2-3.6Z"
                        fill="currentColor"
                    />
                    <path
                        d="M126.7,57.5l5.2,3.8-2-6.1,5.2-3.6h-6.4l-2-6.1-2,6.1h-6.4l5.2,3.6-2,6.1,5.2-3.8Z"
                        fill="currentColor"
                    />
                    <path
                        d="M110,86.4h-1.4v-4.1c0-4.7-3.8-8.5-8.3-8.5s-8.3,3.8-8.3,8.5v4.1h-1.4c-.8,0-1.5.6-1.5,1.5v12.9c0,.8.6,1.5,1.5,1.5h19.6c.8,0,1.5-.6,1.5-1.5v-12.9c-.2-.9-.9-1.5-1.7-1.5ZM95.4,82.4c0-2.7,2.1-4.9,4.9-4.9s4.9,2.1,4.9,4.9v4.1h-9.7v-4.1ZM101.8,94.7v2.6c0,.9-.8,1.5-1.5,1.5s-1.5-.8-1.5-1.5v-2.6c-.6-.5-.9-1.2-.9-2,0-1.4,1.2-2.6,2.6-2.6s2.6,1.2,2.6,2.6c-.2.8-.6,1.5-1.2,2Z"
                        fill="currentColor"
                    />
                    <g>
                        <path
                            d="M83.3,122.8l-1.7,5.1h-2.2l5.5-16.2h2.5l5.5,16.2h-2.2l-1.7-5.1h-5.7ZM88.6,121.2l-1.6-4.7c-.4-1.1-.6-2-.8-3h0c-.2,1-.5,1.9-.8,2.9l-1.6,4.7h4.9Z"
                            fill="currentColor"
                        />
                        <path
                            d="M97.6,127.9l-5.3-16.2h2.3l2.5,8c.7,2.2,1.3,4.2,1.7,6h0c.5-1.9,1.1-3.9,1.8-6l2.7-8h2.2l-5.8,16.2h-2.3Z"
                            fill="currentColor"
                        />
                        <path
                            d="M119.8,127.2c-.9.3-2.8.9-5,.9s-4.5-.6-6-2.1c-1.4-1.3-2.3-3.5-2.3-6,0-4.8,3.3-8.4,8.8-8.4s3.3.4,4,.7l-.5,1.7c-.9-.4-1.9-.7-3.6-.7-3.9,0-6.5,2.4-6.5,6.5s2.5,6.5,6.2,6.5,2.3-.2,2.8-.4v-4.8h-3.3v-1.7h5.3v7.8Z"
                            fill="currentColor"
                        />
                    </g>
                    <text />
                </g>
            </g>
        </svg>
    )
}
