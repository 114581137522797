'use client'
import {useTranslations} from 'use-intl'
export default function IsoIcon() {
    const t = useTranslations()
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            baseProfile="tiny"
            version="1.2"
            viewBox="0 0 200 200"
            width={200}
            fill="currentColor"
            aria-label={t('Arialabels.ISO')}
            role="img"
        >
            <g>
                <g id="Layer_1">
                    <path
                        d="M95.3,72.7c2.3.3,3.7.3,9.9.4h7.2v16.2h-12.9v-1.8s0-1.8,0-1.8l-.9-.4c-2.6-1-6-1.6-9.9-1.6-3.8,0-5.5.3-7.1,1.4-1.7,1.1-1.9,3.3-.5,4.7,1.5,1.5,3.2,1.9,11.2,2.9,6.9.9,9.5,1.4,12.8,2.7,1.6.6,3.6,1.8,4.9,2.9,1.9,1.5,3.4,4.1,4.1,6.8.5,2,.7,5.9.3,7.9-.6,3.5-2,6-4.4,8.3-2.6,2.5-6,4.1-10.6,5.1-5.1,1.2-12.3,1.3-19.6.5-2.5-.3-3.9-.3-9.9-.4h-7v-16.1h12.9v3.3l.6.3c1.9,1,5.8,1.7,9.9,1.8,7.1.2,10.3-1.1,10.3-4.1,0-1.2-.3-1.8-1.3-2.5-1.4-1-3.5-1.5-9.9-2.3-7.8-1-10.8-1.7-14.2-3.3-5.4-2.5-8-6.4-8.6-12.5-.3-3.2.2-6.3,1.5-8.9,2.9-5.9,9.4-9.3,19.5-10.1,2-.2,9.1,0,11.7.4Z"
                        fill="currentColor"
                    />
                    <polygon
                        points="59.1 79.9 59.1 86.6 54.7 86.6 50.4 86.6 50.4 100.1 50.4 113.5 54.7 113.5 59.1 113.5 59.1 120.2 59.1 126.8 41.8 126.8 24.6 126.8 24.5 120.1 24.5 113.5 28.8 113.5 33.2 113.5 33.2 100.1 33.2 86.6 28.9 86.6 24.6 86.5 24.5 79.9 24.5 73.3 41.8 73.3 59.1 73.3 59.1 79.9"
                        fill="currentColor"
                    />
                    <path
                        d="M102.4,38.5c4.2.1,9.2,1,13.7,2.3,13.8,4,25.6,12.5,34.1,24.7.5.7.9,1.3.9,1.4s-.7.2-2.2.2h-2.2l-.7-1c-1.9-2.6-5.6-6.8-8-8.9l-1-.9-2.6.6c-4.6,1.1-9.6,2-14.6,2.6-1.1.1-2.1.3-2.1.4s.2.8.5,1.6c.6,1.9,1.5,5.2,1.5,5.4s-.5.2-1.9.2h-1.9l-.3-.9c-.1-.5-.4-1.3-.5-1.9-.5-1.6-1.2-3.9-1.3-4,0,0-1,0-2.2,0-1.1,0-4.3.2-7.1.3-5.3.2-12.7,0-17.7-.4-1.3-.1-2.4-.1-2.4,0-.1.1-1.2,3.5-1.7,5.4l-.4,1.5h-1.8c-1.7,0-1.9,0-1.9-.3s.9-3.5,1.5-5.6c.3-.8.4-1.5.3-1.5,0,0-.8-.2-1.7-.3-4.1-.5-10-1.6-14.5-2.7l-2.8-.7-.8.7c-2.4,2.1-6.3,6.3-8.3,9.1l-.9,1.2h-2.2c-1.2,0-2.2,0-2.2,0,0-.2,2.4-3.5,3.7-5.2,5.7-7.2,12.8-12.9,21.1-17,6.3-3.1,12.5-5,19.8-5.9,1.8-.2,6.7-.5,7.9-.4.3,0,1.5,0,2.8.1ZM97,42.2c-3.4,1.2-6.7,5-9.6,11.2-.7,1.4-1.2,2.7-1.3,2.8,0,.2,0,.3.7.3,3.7.4,13.4.4,18.7.1,6.4-.4,7-.4,6.9-.7s-2.3-4.8-3.3-6.4c-2-3.1-4-5.3-5.9-6.5-2.1-1.3-4.2-1.6-6.2-.9ZM88.7,42.8c-8,1.7-14.6,4.4-21.4,8.9-1.7,1.1-2.2,1.6-1.8,1.6s1,.2,2.1.4c3.6.8,11.2,2.1,13.8,2.3h.6s.6-1.5.6-1.5c1.8-4.3,4.7-8.9,7-11.3.5-.5.5-.6.2-.5-.2,0-.7,0-1,.2ZM108.8,43.2c1,1.2,2.7,3.5,3.6,4.9,1.1,1.7,2.7,4.9,3.4,6.6l.5,1.3h1c4.7-.6,15.9-2.5,15.9-2.9s-1-.8-2.1-1.5c-6.4-4.2-13.4-7.2-20.5-8.7-2.4-.5-2.4-.5-1.7.2Z"
                        fill="currentColor"
                    />
                    <path
                        d="M152,72.6c6.7,1,12,3.5,16.1,7.6,5,5,7.4,11.4,7.4,19.7,0,4-.4,6.9-1.5,10-3.5,10.6-12.6,16.9-25.5,17.6-6.7.4-13.6-.9-18.4-3.6-3.3-1.8-6.8-5-8.7-7.8-2.3-3.5-3.7-7.5-4.3-12.2-.2-1.9-.2-6.6,0-8.6,1.2-9.3,6.2-16.3,14.2-20.1,3.3-1.6,6.9-2.5,11.4-3,1.5-.1,7.6,0,9.2.3ZM144.2,85c-5,.8-7.9,4.3-8.8,10.7-.3,2.5-.2,7.9.2,10,1.4,6.6,5.1,9.6,11.1,9.3,3.2-.1,4.9-.8,6.7-2.7,2.6-2.6,3.7-6.2,3.7-12.3,0-5.1-.7-8.3-2.5-11-1.2-1.9-3.2-3.2-5.4-3.8-1.2-.3-3.8-.4-5.1-.2Z"
                        fill="currentColor"
                    />
                    <path
                        d="M52,133.8c1.9,2.7,6.1,7.3,8.4,9.4l.9.7,2.4-.6c4.7-1.2,9.3-2,14.1-2.6,1.5-.2,2.7-.3,2.7-.4s-.2-.7-.4-1.5c-.6-1.9-1.5-5.1-1.5-5.5s.2-.3,1.9-.3h1.8l.4,1.5c.2.8.7,2.4,1,3.5l.6,2h.8c4.8-.6,17.4-.7,23.3-.3,1.9.1,3.8.2,4.3.2h1l.5-1.6c.3-.9.7-2.4,1-3.4l.5-1.8h1.9c1.3,0,1.9,0,1.9,0,0,.3-1.3,4.9-1.7,6.1l-.4,1.1h.5c.3.1,1.3.3,2.3.4,4.4.6,9.8,1.6,14,2.6,2.1.6,2.4.6,2.7.4.7-.5,4-3.7,5.5-5.4.9-1,2.2-2.6,2.9-3.6l1.3-1.7h2.1c1.2,0,2.2,0,2.2.1,0,.2-2,3-3.1,4.5-10.3,13.3-25.6,21.8-42.4,23.7-2.8.3-10.8.3-13.5,0-13.7-1.8-25.2-7.2-35.2-16.5-3.4-3.2-7.8-8.4-9.6-11.6,0-.1.4-.2,2.1-.2h2.2l.5.7ZM92.7,143.2c-4,.2-6.8.4-6.9.4-.1.1,1.8,4,2.8,5.7,2.1,3.7,4.2,6.1,6.4,7.5,1.4.9,2.3,1.2,3.7,1.3,1.4.1,2.3-.1,3.9-.9,1.7-.9,4.2-3.4,5.9-5.9,1.1-1.7,3.9-7,3.9-7.5s-.3-.3-3.2-.5c-2.5-.2-14.4-.3-16.4-.2ZM80.4,144.2c-.2,0-1.7.2-3.2.4-3.6.5-6.4,1-9.6,1.6-2,.4-2.5.6-2.4.7.3.3,4.2,2.9,6.2,4,5.1,2.9,10.7,5,16.2,6.1,1.2.3,2.2.4,2.3.4s-.2-.4-.6-.9c-2.4-2.8-4.7-6.5-6.6-10.8l-.7-1.6h-.6c-.3,0-.8,0-1,0ZM115.9,145c-.6,1.4-2.4,5.1-3.4,6.7-1,1.7-2.3,3.5-3.4,4.8-.5.5-.8,1-.8,1,.2.2,5.3-1.1,8-1.9,4.9-1.6,11.1-4.6,15.5-7.7l1.3-.9-.8-.2c-4-.9-8.8-1.8-12.6-2.3-1.3-.2-2.6-.3-2.9-.4q-.5,0-.9.9Z"
                        fill="currentColor"
                    />
                </g>
            </g>
        </svg>
    )
}
